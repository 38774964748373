'use strict';

// Opciones slider
var optionsType =
{
    dots           : false,
    arrows         : true,
    infinite       : true,
    vertical       : false,
    slidesToShow   : 4,
    slidesToScroll : 1,
    responsive     : [{
        breakpoint : 1200,
        settings   : {
            slidesToShow   : 3,
            slidesToScroll : 3,
            arrows         : true
        }
    },
    {
        breakpoint : 991,
        settings   : {
            slidesToShow   : 3,
            slidesToScroll : 3,
            arrows         : true
        }
    },
    {
        breakpoint : 768,
        settings   : {
            slidesToShow   : 2,
            slidesToScroll : 1,
            arrows         : true
        }
    },
    {
        breakpoint : 500,
        settings   : {
            slidesToShow   : 2,
            slidesToScroll : 1,
            arrows         : true
        }
    }]
};

/**
 * Validates and Return the cquotient namespace provided by the commerce cloud platform
 * @returns {Object} - einsteinUtils or null
 */
function getEinsteinUtils() {
    var einsteinUtils = window.CQuotient;
    if (einsteinUtils && (typeof einsteinUtils.getCQUserId === 'function') && (typeof einsteinUtils.getCQCookieId === 'function')) {
        return einsteinUtils;
    }
    return null;
}

/**
 * fills in the carousel with product tile html objects
 * @param {string} einsteinResponse string html for product tiles
 * @param {jQuery} $parentElement parent element where recommendations will show.
 */
function fillDomElement(einsteinResponse, $parentElement) {
    var recommender = $parentElement.data('recommender');
    var recommendedProducts = einsteinResponse[recommender].recs;
    if (recommendedProducts && recommendedProducts.length > 0) {
        var template = $parentElement.data('template');
        var quickView = $parentElement.data('quickview');
        var showButtons = $parentElement.data('showbuttons');
        var showRibbons = $parentElement.data('showribbons');
        var hasSlick = $parentElement.data('hascarousel');
        var suffix = $parentElement.data('preffix');
        var components = [];
        components = recommendedProducts.map(function (recommendedProduct) {
            var tiledefinition = {};
            tiledefinition.template = template;
            tiledefinition.quickView = quickView;
            tiledefinition.showButtons = showButtons;
            tiledefinition.showRibbons = showRibbons;
            tiledefinition.model = {
                type : 'product',
                id   : recommendedProduct.id
            };
            return tiledefinition;
        });

        var url = new URL($parentElement.data('product-load-url'));
        url.searchParams.append('recommender', recommender);
        url.searchParams.append('components', JSON.stringify(components));
        $.ajax({
            url      : url.href,
            type     : 'get',
            dataType : 'html',
            success  : function (html) {
                var idInner = '#' + $parentElement.data('id');
                var einsteinCarrousel = $(idInner);
                if (einsteinCarrousel.length > 0) {
                    einsteinCarrousel.html(html);
                    $(idInner + ' script').each(function () {
                        var scriptElement = $(this).clone();
                        $(this).remove();
                        scriptElement.insertAfter(idInner);
                    });
                    $('#recommendationTypeEinstein1-' + suffix + ' h3.title').removeClass('d-none');
                    if (hasSlick) {
                        if (components.length >= optionsType.slidesToShow) {
                            einsteinCarrousel.slick(optionsType);
                        } else {
                            einsteinCarrousel.slick();
                        }
                    }
                }
            },
            error: function () {
                $parentElement.spinner().stop();
            }
        });
    }
}

/**
 * Processes a recommendation tile, with an already initialized category specific anchors array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @param {Object} einsteinUtils cquotient object
 * @param {Array} anchorsArray array of objects representing anchors
 */
function processRecommendationsTile($parentElement, einsteinUtils, anchorsArray) {
    var recommender = $parentElement.data('recommender');

    var params = {
        userId   : einsteinUtils.getCQUserId(),
        cookieId : einsteinUtils.getCQCookieId(),
        ccver    : '1.01'
    };

    if (anchorsArray) {
        params.anchors = anchorsArray;
    }

    /**
    * Processes a recommendation responses
    * @param {Object} einsteinResponse cquotient object
    */
    function recommendationsReceived(einsteinResponse) {
        fillDomElement(einsteinResponse, $parentElement);
        $parentElement.spinner().stop();
    }

    if (einsteinUtils.getRecs) {
        einsteinUtils.getRecs(einsteinUtils.clientId, recommender, params, recommendationsReceived);
    } else {
        einsteinUtils.widgets = einsteinUtils.widgets || []; // eslint-disable-line no-param-reassign
        einsteinUtils.widgets.push({
            recommenderName : recommender,
            parameters      : params,
            callback        : recommendationsReceived
        });
    }
}

/**
 * Processes a recommendation tile, with an already initialized product specific anchors array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @returns {Array} - containing an anchor object
 */
function createProductAnchor($parentElement) {
    return [{
        id     : $parentElement.data('primaryProductId'),
        sku    : $parentElement.data('secondaryProductId'),
        type   : $parentElement.data('alternativeGroupType'),
        alt_id : $parentElement.data('alternativeGroupId')
    }];
}

/**
 * Rerieves data attributes from parent element and converts to gretel compatible recommenders array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @returns {Array} - containing an anchor object
 */
function createCategoryAnchor($parentElement) {
    return [{ id: $parentElement.data('categoryId') }];
}

/**
 * Gets all placeholder elements, which hold einstein recommendations queries the details from the
 * einstein engine and feeds them back to the dom element
 */
function loadRecommendations() {
    var einsteinUtils = getEinsteinUtils();
    if (einsteinUtils) {
        var $recommendationTiles = $('.einstein-carousel');
        $recommendationTiles.each(function () {
            var $parentElement = $(this);
            $parentElement.spinner().start();
            if ($(this).closest('.experience-einstein-CarouselProduct').length) {
                return processRecommendationsTile($parentElement, einsteinUtils, createProductAnchor($parentElement));
            } else if ($(this).closest('.experience-einstein-CarouselCategory').length) {
                return processRecommendationsTile($parentElement, einsteinUtils, createCategoryAnchor($parentElement));
            }
            return processRecommendationsTile($parentElement, einsteinUtils);
        });
    }
}

$(document).ready(function () {
    loadRecommendations();
});
